export const COOKIE_KEYS = {
  iesoCookieTimeout: 'iesoCookieTimeout',
};

const cookieDomain = 'iesohealth.uk';
const patientCookieDomain = 'thinkwell.co.uk';

function checkLocalEnv() {
  return window.location.hostname === 'localhost';
}

function getDomain(patient = false): string {
  const prod = (global as any)?.__ieso_config.userConfigPex.authority.includes('iesohealth.uk');
  const localDev = checkLocalEnv();
  if (localDev) {
    return 'localhost';
  } else if (patient && !prod) {
    return patientCookieDomain;
  } else {
    return cookieDomain;
  }
}

export function createCookie<T = string>(name: string, value: T, patient = false) {
  const cookieValue = `${name}=${value}`;
  const domainValue = getDomain();
  const domain = `domain=${domainValue}`;
  const options = 'path=/';

  document.cookie = `${cookieValue}; ${domain}; ${options}`;
}

export function readCookie(name: string, source?: string) {
  const cookieSource = source || document.cookie;
  const cookies = cookieSource.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(name + '=') === 0) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

export function deleteCookie(name: string, patient = false) {
  const domainValue = getDomain();
  const domain = `domain=${domainValue}`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${domain}`;
}

type TListenCallback = (arg: { oldValue: string; newValue: string }) => void;

export function listenCookieChange(callback: TListenCallback) {
  let lastCookie = document.cookie;
  return setInterval(() => {
    const cookie = document.cookie;
    if (cookie !== lastCookie) {
      try {
        callback({ oldValue: lastCookie, newValue: cookie });
      } finally {
        lastCookie = cookie;
      }
    }
  }, 1000);
}
